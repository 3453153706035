<template>
  <div>
    <h3 class="title">
      {{food.name}}
    </h3>

    <div class="message" v-if="food.ndbn">
      <div class="message-header">
        <span>USDA NDBN #{{ food.ndbn }}</span>
      </div>

      <div class="message-body">
        <a :href="'https://ndb.nal.usda.gov/ndb/foods/show/' + food.ndbn">USDA DB Entry</a>
      </div>
    </div>

    <div class="message">
      <div class="message-header">
        Custom Units
      </div>

      <div class="message-body">
        <ul>
          <li v-for="fu in food.food_units" :key="fu.id">
            {{fu.name}}: {{fu.gram_weight}} grams
          </li>
        </ul>
      </div>
    </div>

    <div class="message">
      <div class="message-header">
        Nutrition per 100 grams
      </div>

      <div class="message-body">
        <div class="columns is-mobile is-multiline">
          <div v-for="(nutrient, name) in nutrients" :key="name" class="column is-half-mobile is-one-third-tablet">
            <label class="label is-small-mobile">{{nutrient.label}}</label>
            <div class="field has-addons">
              <div class="control is-expanded">
                <input type="text" class="input is-small-mobile" disabled="true" v-model="food[name]">
              </div>
              <div class="control">
                <button type="button" tabindex="-1" class="unit-label button is-static is-small-mobile">{{nutrient.unit}}</button>
              </div>
            </div>
          </div>
        </div>
      </div>

    </div>
  </div>
</template>

<script>

  import { mapState } from "vuex";

  export default {
    props: {
      food: {
        required: true,
        type: Object
      }
    },

    computed: {
      ...mapState({
        nutrients: 'nutrientList'
      }),
    }
  }

</script>

<style lang="scss" scoped>
</style>