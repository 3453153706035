<template>
  <div>
    <app-validation-errors :errors="validationErrors"></app-validation-errors>

    <label class="label is-small">Add New List</label>
    <div class="field has-addons">
      <div class="control">
        <input class="input is-small" type="text" v-model="taskList.name" @keydown="nameKeydownHandler">
      </div>
      <div class="control">
        <button type="button" class="button is-primary is-small" @click="save">Add</button>
      </div>
    </div>
  </div>
</template>

<script>

  export default {
    props: {
      taskList: {
        required: true,
        type: Object
      },

      validationErrors: {
        required: false,
        type: Object,
        default: function() { return {}; }
      }
    },

    methods: {
      save() {
        this.$emit("save");
      },

      nameKeydownHandler(evt) {
        switch (evt.key) {
          case "Enter":
            evt.preventDefault();
            this.save();
        }
      }
    }
  }

</script>