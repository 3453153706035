<template>
  <app-modal :open="open" :title="message" @dismiss="runCancel">
    <div class="buttons">
      <button type="button" class="button is-primary" @click="runConfirm">OK</button>
      <button type="button" class="button" @click="runCancel">Cancel</button>
    </div>
  </app-modal>
</template>

<script>

  export default {
    props: {
      cancel: {
        type: Function,
        required: true
      },

      confirm: {
        type: Function,
        required: true
      },

      message: {
        type: String,
        required: false,
        default: 'Are you sure?'
      },

      open: {
        type: Boolean,
        required: true
      }
    },

    methods: {
      runConfirm() {
        this.confirm();
      },

      runCancel() {
        this.cancel();
      }
    }
  }

</script>