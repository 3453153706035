<template>
  <div>

    <log-edit v-if="log.recipe !== null" :log="log" :validation-errors="validationErrors">
      <div class="buttons">
        <button type="button" class="button is-primary" @click="save">Save Log</button>
        <router-link class="button is-secondary" to="/">Cancel</router-link>
      </div>
    </log-edit>

    <div class="buttons">
      <button type="button" class="button is-primary" @click="save">Save Log</button>
      <router-link class="button is-secondary" to="/">Cancel</router-link>
    </div>

  </div>
</template>

<script>

  import LogEdit from "./LogEdit";
  import { mapState } from "vuex";
  import api from "../lib/Api";
  import * as Errors from '../lib/Errors';

  export default {
    data() {
      return {
        validationErrors: {},
        log: {
          date: null,
          rating: null,
          notes: null,
          recipe: null
        }
      }
    },

    computed: {
      ...mapState({
        recipeId: state => state.route.params.recipeId,
      })
    },

    methods: {
      save() {
        this.log.original_recipe_id = this.recipeId;
        this.validationErrors = {};

        this.loadResource(
          api.postLog(this.log)
            .then(() => this.$router.push('/'))
            .catch(Errors.onlyFor(Errors.ApiValidationError, err => this.validationErrors = err.validationErrors()))
        );
      }
    },

    created() {
      this.loadResource(
        api.getRecipe(this.recipeId, null, null, null, data => this.log.recipe = data)
      );
    },

    components: {
      LogEdit
    }
  }

</script>

<style lang="scss" scoped>
</style>