<template>
  <div>

    <div v-if="recipe === null">
      Loading...
    </div>
    <div v-else>
      <h1 class="title">Editing {{ recipe.name || "[Unamed Recipe]" }}</h1>

      <app-validation-errors :errors="validationErrors"></app-validation-errors>

      <recipe-edit :recipe="recipe"></recipe-edit>
    </div>

    <button type="button" class="button is-primary" @click="save">Save</button>
    <router-link class="button is-secondary" to="/">Cancel</router-link>

  </div>
</template>

<script>

  import RecipeEdit from "./RecipeEdit";
  import { mapState } from "vuex";
  import api from "../lib/Api";
  import * as Errors from '../lib/Errors';

  export default {
    data: function () {
      return {
        recipe: null,
        validationErrors: {}
      }
    },

    computed: {
      ...mapState({
        recipeId: state => state.route.params.id,
      })
    },

    methods: {
      save() {
        this.validationErrors = {};
        this.loadResource(
          api.patchRecipe(this.recipe)
            .then(() => this.$router.push({name: 'recipe', params: {id: this.recipeId }}))
            .catch(Errors.onlyFor(Errors.ApiValidationError, err => this.validationErrors = err.validationErrors()))
        );
      }
    },

    created() {
      this.loadResource(
        api.getRecipe(this.recipeId, null, null, null, data => { this.recipe = data; return data; })
      );
    },

    components: {
      RecipeEdit
    }
  }

</script>

<style lang="scss" scoped>
</style>