<template>
  <div>
    <div class="buttons">
      <button type="button" class="button is-primary" @click="addNote">Add Note</button>
    </div>

    <app-modal title="Add Note" :open="editNote !== null" @dismiss="cancelNote">
      <note-edit v-if="editNote !== null" :note="editNote" @save="saveNote" @cancel="cancelNote"></note-edit>
    </app-modal>

    <table class="table">
      <tr>
        <th>Note</th>
        <th>Date</th>
        <th></th>
      </tr>

      <tr v-for="n in notes" :key="n.id">
        <td>
          {{ n.content }}
        </td>
        <td>
          <app-date-time :date-time="n.created_at"></app-date-time>
        </td>
        <td>
          <button type="button" class="button is-danger" @click="deleteNote(n)">
            <app-icon icon="x"></app-icon>
          </button>
        </td>
      </tr>

    </table>
  </div>

</template>

<script>

  import api from "../lib/Api";
  import NoteEdit from "./NoteEdit";

  export default {
    data() {
      return {
        notes: [],
        editNote: null
      };
    },

    methods: {
      refreshList() {
        this.loadResource(
          api.getNoteList()
            .then(data => this.notes = data)
        );
      },

      addNote() {
        this.editNote = { id: null, content: "" };
      },

      saveNote() {
        this.loadResource(
          api.postNote(this.editNote)
            .then(() => {
              this.editNote = null;
              return this.refreshList();
            })
        );
      },

      cancelNote() {
        this.editNote = null;
      },

      deleteNote(n) {
        this.loadResource(
          api.deleteNote(n)
            .then(() => {
              return this.refreshList();
            })
        );
      }
    },

    created() {
      this.refreshList();
    },

    components: {
      NoteEdit
    }
  }

</script>

<style lang="scss" scoped>
</style>