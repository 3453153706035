<template>
  <div>
    <h1>404!</h1>
    <p>WTF?</p>
  </div>
</template>

<script>

  export default {

  }

</script>