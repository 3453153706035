<template>
  <div ref="container">
    <div ref="modal" :class="['popup', 'modal', { 'is-wide': wide, 'is-active': open && error === null }]">
      <div class="modal-background" @click="close"></div>
      <div class="modal-card">
        <header class="modal-card-head">
          <slot name="title">
            <p class="modal-card-title">{{ title }}</p>
            <app-icon class="close-button" icon="x" aria-label="close" @click="close"></app-icon>
          </slot>
        </header>

        <section class="modal-card-body">
          <slot></slot>
        </section>
      </div>
    </div>
  </div>
</template>

<script>

  import { mapState } from "vuex";

  export default {
    props: {
      open: {
        type: Boolean,
        default: false
      },
      title: String,
      wide: {
        type: Boolean,
        default: false
      }
    },

    mounted() {
      this.$root.$el.appendChild(this.$refs.modal);
    },

    beforeDestroy() {
      this.$refs.container.appendChild(this.$refs.modal);
    },

    computed: {
      ...mapState([
        'error'
      ])
    },

    methods: {
      close() {
        this.$emit("dismiss");
      }
    },

    components: {
    }
  }

</script>

<style lang="scss" scoped>

  .close-button {
    cursor: pointer;
  }

</style>