<template>
  <div>
    <div v-if="log === null">
      Loading...
    </div>
    <div v-else>
      <log-show :log="log"></log-show>
    </div>

    <div class="buttons">
      <router-link v-if="isLoggedIn" class="button" :to="{name: 'edit_log', params: { id: logId }}">Edit</router-link>
      <router-link class="button" to="/logs">Back</router-link>
    </div>
  </div>
</template>

<script>

  import LogShow from "./LogShow";
  import { mapState } from "vuex";
  import api from "../lib/Api";

  export default {
    data: function () {
      return {
        log: null
      }
    },

    computed: {
      ...mapState({
        logId: state => state.route.params.id,
      })
    },

    created() {
      this.loadResource(
        api.getLog(this.logId)
          .then(data => { this.log = data; return data; })
      );
    },

    components: {
      LogShow
    }
  }

</script>

<style lang="scss" scoped>
</style>