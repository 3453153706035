<template>
  <app-text-field :value="stringValue" @input="input" :label="label" type="date"></app-text-field>
</template>

<script>

  import DateTimeUtils from "../lib/DateTimeUtils";

  export default {
    props: {
      value: {
        required: false,
        type: [Date, String]
      },

      label: {
        required: false,
        type: String,
        default: null
      }
    },

    computed: {
      stringValue() {
        const d = DateTimeUtils.toDate(this.value);
        return DateTimeUtils.formatDateForEdit(d);
      }
    },

    methods: {
      input(val) {
        let d = DateTimeUtils.toDate(val + " 00:00");
        this.$emit("input", d);
      }
    }
  }

</script>

<style lang="scss" scoped>

</style>