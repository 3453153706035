import '../styles';
import "vue-resize/dist/vue-resize";

import Vue from 'vue'
import { sync } from 'vuex-router-sync';
import { swInit } from "../lib/ServiceWorker";
import responsiveSync from "../lib/VuexResponsiveSync";
import { createChannel } from "../lib/ActionCable";
import VueProgressBar from "vue-progressbar";
import VueResize from "vue-resize";
import config from '../config';
import store from '../store';
import router from '../router';
import '../lib/GlobalMixins';
import App from '../components/App';

import AppAutocomplete from "../components/AppAutocomplete";
import AppConfirm from "../components/AppConfirm";
import AppDateTime from "../components/AppDateTime";
import AppDatePicker from "../components/AppDatePicker";
import AppDropdown from "../components/AppDropdown";
import AppExpandTransition from "../components/AppExpandTransition";
import AppIcon from "../components/AppIcon";
import AppIconicIcon from "../components/AppIconicIcon";
import AppModal from "../components/AppModal";
import AppNavbar from "../components/AppNavbar";
import AppPager from "../components/AppPager";
import AppRating from "../components/AppRating";
import AppSearchText from "../components/AppSearchText";
import AppTagEditor from "../components/AppTagEditor";
import AppTextField from "../components/AppTextField";
import AppValidationErrors from "../components/AppValidationErrors";

Vue.component("AppAutocomplete", AppAutocomplete);
Vue.component("AppConfirm", AppConfirm);
Vue.component("AppDateTime", AppDateTime);
Vue.component("AppDatePicker", AppDatePicker);
Vue.component("AppDropdown", AppDropdown);
Vue.component("AppExpandTransition", AppExpandTransition);
Vue.component("AppIcon", AppIcon);
Vue.component("AppIconicIcon", AppIconicIcon);
Vue.component("AppModal", AppModal);
Vue.component("AppNavbar", AppNavbar);
Vue.component("AppPager", AppPager);
Vue.component("AppRating", AppRating);
Vue.component("AppSearchText", AppSearchText);
Vue.component("AppTagEditor", AppTagEditor);
Vue.component("AppTextField", AppTextField);
Vue.component("AppValidationErrors", AppValidationErrors);


Vue.use(VueProgressBar, {
//  color: '#bffaf3',
//  failedColor: '#874b4b',
//   thickness: '5px',
//   transition: {
//     speed: '0.2s',
//     opacity: '0.6s',
//     termination: 300
//   },
//   autoRevert: true,
//   location: 'left',
//   inverse: false
});

Vue.use(VueResize);

sync(store, router);
responsiveSync(store);

Vue.prototype.$createChannel = function(...args) {
  createChannel(null, ...args);
}

document.addEventListener('DOMContentLoaded', () => {

  const app = document.getElementById('app');
  config.baseApiUrl = app.dataset.url;

  window.$vm = new Vue({
    el: '#app',
    store,
    router,
    render: createElement => createElement('App'),
    mounted() {
      this.$nextTick(() => swInit(store));
    },
    components: { App }
  });

});
