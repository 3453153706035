<template>
  <div>

    <div class="panel">
      <p class="panel-heading">
        {{taskList.name}} ({{completedItemCount}} / {{taskList.task_items.length}})
      </p>

      <div class="panel-block">
        <button class="button is-fullwidth is-primary" @click="toggleShowAddItem">{{ showAddItem ? 'Done' : 'New Item' }}</button>
      </div>

      <app-expand-transition>
        <div class="panel-block" v-if="showAddItem">
          <task-item-edit @save="save" :task-item="newItem" ref="itemEdit"></task-item-edit>
        </div>
      </app-expand-transition>

      <transition-group tag="div" name="list-item-move">
        <a v-for="i in taskItems" :key="i.id" @click="toggleItem(i)" class="panel-block">
          <div class="check">
            <app-icon v-if="i.completed" icon="check"></app-icon>
            <span class="icon" v-else></span>
          </div>
          <span>{{ i.quantity }} {{ i.name }}</span>
        </a>
      </transition-group>

      <app-expand-transition>
        <div class="panel-block" v-if="uncompletedItemCount > 0">
          <button class="button is-fullwidth is-link" @click="completeAllItems">
            <span class="check">
              <app-icon icon="check"></app-icon>
            </span>
            <span>Check All</span>
          </button>
        </div>
      </app-expand-transition>
      <app-expand-transition>
        <div class="panel-block" v-if="completedItemCount > 0">
          <button class="button is-fullwidth is-link" @click="unCompleteAllItems">
            <span class="check">
              <span class="icon"></span>
            </span>
            <span>Uncheck All</span>
          </button>
        </div>
      </app-expand-transition>
      <app-expand-transition>
        <div class="panel-block" v-if="completedItemCount > 0">
          <button class="button is-fullwidth is-link" @click="deleteCompletedItems">
            <app-icon icon="x" class="is-text-danger"></app-icon>
            <span>Clear Completed</span>
          </button>
        </div>
      </app-expand-transition>

    </div>

  </div>
</template>

<script>

  import * as Errors from '../lib/Errors';
  import { mapActions } from "vuex";
  import cloneDeep from "lodash/cloneDeep";

  import TaskItemEdit from "./TaskItemEdit";

  const newItemTemplate = function(listId) {
    return {
      task_list_id: listId,
      name: '',
      quantity: '',
      completed: false
    };
  };

  export default {
    props: {
      taskList: {
        required: true,
        type: Object
      }
    },

    data() {
      return {
        showAddItem: false,
        newItem: null,
        newItemValidationErrors: {}
      };
    },

    computed: {
      completedItemCount() {
        return this.taskList === null ? 0 : this.taskList.task_items.filter(i => i.completed).length;
      },

      uncompletedItemCount() {
        return this.taskList === null ? 0 : this.taskList.task_items.filter(i => !i.completed).length;
      },
      completedTaskItems() {
        return (this.taskList ? this.taskList.task_items : []).filter(i => i.completed);
      },

      uncompletedTaskItems() {
        return (this.taskList ? this.taskList.task_items : []).filter(i => !i.completed);
      },

      taskItems() {
        return this.uncompletedTaskItems.concat(this.completedTaskItems);
      }
    },

    methods: {
      ...mapActions([
        'createTaskItem',
        'updateTaskItem',
        'deleteTaskItems',
        'completeTaskItems'
      ]),

      save() {
        this.loadResource(
          this.createTaskItem(this.newItem)
            .then(() => {
              this.newItem = newItemTemplate(this.taskList.id);
              this.$refs.itemEdit.focus();
            })
            .catch(Errors.onlyFor(Errors.ApiValidationError, err => this.newItemValidationErrors = err.validationErrors()))
        )
      },

      toggleItem(i) {
        this.loadResource(
          this.completeTaskItems({
            taskList: this.taskList,
            taskItems: [i],
            completed: !i.completed
          })
        );
      },

      toggleShowAddItem() {
        this.newItem = newItemTemplate(this.taskList.id);
        this.showAddItem = !this.showAddItem;
      },

      completeAllItems() {
        const toComplete = this.taskList.task_items.filter(i => !i.completed);
        this.loadResource(
          this.completeTaskItems({
            taskList: this.taskList,
            taskItems: toComplete,
            completed: true
          })
        )
      },

      unCompleteAllItems() {
        const toUnComplete = this.taskList.task_items.filter(i => i.completed);
        this.loadResource(
          this.completeTaskItems({
            taskList: this.taskList,
            taskItems: toUnComplete,
            completed: false
          })
        )
      },

      deleteCompletedItems() {
        this.loadResource(
          this.deleteTaskItems({
            taskList: this.taskList,
            taskItems: this.taskList.task_items.filter(i => i.completed)
          })
        );
      },

    },

    components: {
      TaskItemEdit
    }
  }

</script>

<style lang="scss" scoped>

  @import "../styles/variables";

  .columns {
    margin-top: 0;
    margin-bottom: 0;
  }

  .column {
    padding-top: 0;
    padding-bottom: 0;
    margin-top: 0;
    margin-bottom: 0;
  }

  .check {
    display: inline-flex;
    margin-right: 1.5rem;

    .icon {
      position: relative;
      &:after {
        content: '';
        position: absolute;
        top: -3px;
        left: -3px;
        bottom: -3px;
        right: -3px;
        border: 2px solid currentColor;
      }
    }
  }

</style>