<template>
  <div class="tag-editor control">
    <input ref="input" type="text" class="input" :value="tagText" @input="inputHandler" @focus="getFocus" @blur="loseFocus">
    <div class="tags">
      <span v-for="t in value" :key="t" class="tag">{{t}}</span>
    </div>
  </div>
</template>

<script>

  export default {
    props: {
      value: {
        required: true,
        type: Array
      }
    },

    data() {
      return {
        hasFocus: false
      };
    },

    computed: {
      tagText() {
        return this.value.join(" ");
      }
    },

    watch: {
    },

    methods: {
      inputHandler(el) {
        let str = el.target.value;
        this.checkInput(str);
        this.$nextTick(() => {
          el.target.value = str;
        });
      },

      checkInput(str) {
        if (this.hasFocus) {
          const m = str.match(/\S\s+\S*$/);

          if (m !== null) {
            str = str.substring(0, m.index + 1);
          } else {
            str = "";
          }
        }

        const newTags = [...new Set(str.toString().split(/\s+/).filter(t => t.length > 0))];

        if (!this.arraysEqual(newTags, this.value)) {
          this.$emit("input", newTags);
        }
      },

      getFocus() {
        this.hasFocus = true;
      },

      loseFocus() {
        this.hasFocus = false;
        this.checkInput(this.$refs.input.value);

      },

      arraysEqual(arr1, arr2) {
        if(arr1.length !== arr2.length)
          return false;
        for(let i = arr1.length; i--;) {
          if(arr1[i] !== arr2[i])
            return false;
        }

        return true;
      }
    }
  }

</script>