<template>
  <div>

    <h1 class="title">Creating {{ recipe.name || "[Unamed Recipe]" }}</h1>

    <app-validation-errors :errors="validationErrors"></app-validation-errors>

    <recipe-edit :recipe="recipe" action="Creating"></recipe-edit>

    <button type="button" class="button is-primary" @click="save">Save</button>
    <router-link class="button is-secondary" to="/">Cancel</router-link>

  </div>
</template>

<script>

  import RecipeEdit from "./RecipeEdit";
  import api from "../lib/Api";
  import * as Errors from '../lib/Errors';

  export default {
    data() {
      return {
        validationErrors: {},
        recipe: {
          name: null,
          source: null,
          description: null,
          yields: null,
          total_time: null,
          active_time: null,
          step_text: null,
          tags: [],
          ingredients: []
        }
      }
    },

    methods: {
      save() {
        this.validationErrors = {};
        this.loadResource(
          api.postRecipe(this.recipe)
            .then(() => this.$router.push('/'))
            .catch(Errors.onlyFor(Errors.ApiValidationError, err => this.validationErrors = err.validationErrors()))
        );
      }
    },

    components: {
      RecipeEdit
    }
  }

</script>

<style lang="scss" scoped>
</style>