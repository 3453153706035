<template>
  <div>

    <button class="button" type="button" @click="openDialog">
      Login
    </button>

    <app-modal title="Login" :open="showLogin" @dismiss="showLogin = false">
      <div>
        <form @submit.prevent="performLogin">

          <div v-if="loginMessage" class="notification is-danger">
            {{loginMessage}}
          </div>

          <div class="field">
            <label class="label">Username</label>
            <div class="control has-icons-left">
              <input class="input" type="text" placeholder="username" ref="usernameInput" v-model="username">
              <app-icon icon="person" size="sm" class="is-left"></app-icon>
            </div>
          </div>

          <div class="field">
            <label class="label">Password</label>
            <div class="control has-icons-left">
              <input class="input" type="password" placeholder="password" v-model="password">
              <app-icon icon="lock-locked" size="sm" class="is-left"></app-icon>
            </div>
          </div>

          <div class="field is-grouped">
            <div class="control">
              <button type="submit" class="button is-primary" :disabled="!enableSubmit">Login</button>
            </div>
            <div class="control">
              <button class="button is-secondary" @click="showLogin = false">Cancel</button>
            </div>
          </div>

        </form>
      </div>
    </app-modal>

  </div>
</template>

<script>

  import api from "../lib/Api";
  import { mapActions, mapState } from "vuex";

  export default {
    data() {
      return {
        showLogin: false,
        error: '',
        username: '',
        password: ''
      };
    },

    computed: {
      ...mapState([
        'loginMessage'
      ]),
      enableSubmit() {
        return this.username !== '' && this.password !== '' && !this.isLoading;
      }
    },

    methods: {
      ...mapActions([
        'login'
      ]),

      openDialog() {
        this.showLogin = true;
        this.$nextTick(() => this.$refs.usernameInput.focus());
      },

      performLogin() {
        if (this.username !== '' && this.password !== '') {
          const params = {username: this.username, password: this.password};

          this.loadResource(
            this.login(params)
              .then(data => {
                if (data.success) {
                  this.showLogin = false;
                }
              })
          );
        }
      }
    },

    components: {
    }
  }

</script>

<style lang="scss" scoped>
</style>