<template>
  <div>

    <log-edit v-if="log !== null" :log="log" :validation-errors="validationErrors">
      <div class="buttons">
        <button type="button" class="button is-primary" @click="save">Save Log</button>
        <router-link class="button is-secondary" to="/">Cancel</router-link>
      </div>
    </log-edit>

    <div class="buttons">
      <button type="button" class="button is-primary" @click="save">Save Log</button>
      <router-link class="button is-secondary" to="/">Cancel</router-link>
    </div>

  </div>
</template>

<script>

  import { mapState } from "vuex";
  import api from "../lib/Api";
  import * as Errors from "../lib/Errors";
  import LogEdit from "./LogEdit";

  export default {
    data() {
      return {
        validationErrors: {},
        log: null
      }
    },

    computed: {
      ...mapState({
        logId: state => state.route.params.id,
      })
    },

    methods: {
      save() {
        this.validationErrors = {};
        this.loadResource(
          api.patchLog(this.log)
            .then(() => this.$router.push('/'))
            .catch(Errors.onlyFor(Errors.ApiValidationError, err => this.validationErrors = err.validationErrors()))
        );
      }
    },

    created() {
      this.loadResource(
        api.getLog(this.logId)
          .then(data => { this.log = data; return data; })
      );
    },

    components: {
      LogEdit
    }
  }

</script>

<style lang="scss" scoped>
</style>