<template>
  <div>

    <h1 class="title">Edit User</h1>

    <app-validation-errors :errors="validationErrors"></app-validation-errors>

    <user-edit v-if="userObj != null" :user-obj="userObj" action="Creating"></user-edit>

    <button type="button" class="button is-primary" @click="save">Save</button>
    <router-link class="button is-secondary" to="/">Cancel</router-link>

  </div>
</template>

<script>

  import UserEdit from "./UserEdit";
  import api from "../lib/Api";
  import * as Errors from '../lib/Errors';

  export default {
    data() {
      return {
        validationErrors: {},
        userObj: null
      }
    },

    created() {
      this.refreshUser();
    },

    watch: {
      user() {
        this.refreshUser();
      }
    },

    methods: {
      refreshUser() {
        if (this.user) {
          this.userObj = {
            username: this.user.username,
            full_name: this.user.full_name,
            email: this.user.email,
            password: '',
            password_confirmation: ''
          };
        } else {
          this.userObj = null;
        }
      },

      save() {
        this.validationErrors = {};
        this.loadResource(
          api.patchUser(this.userObj)
            .then(() => this.checkAuthentication())
            .then(() => {
              this.$router.push('/');
            })
            .catch(Errors.onlyFor(Errors.ApiValidationError, err => this.validationErrors = err.validationErrors()))
        );
      }
    },

    components: {
      UserEdit
    }
  }

</script>

<style lang="scss" scoped>

</style>