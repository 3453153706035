<template>
  <div>
    <h1 class="title">
      Log Entries
    </h1>

    <table class="table">
      <tr>
        <th>Recipe</th>
        <th>Date</th>
        <th>Rating</th>
        <th>Notes</th>
      </tr>

      <tr v-for="l in logs" :key="l.id">
        <td> <router-link :to="{name: 'log', params: {id: l.id}}">{{l.recipe.name}}</router-link></td>
        <td><app-date-time :date-time="l.date" :show-time="false"></app-date-time> </td>
        <td><app-rating :value="l.rating" readonly></app-rating></td>
        <td>{{l.notes}}</td>
      </tr>

    </table>
  </div>
</template>

<script>

  import api from "../lib/Api";
  import debounce from "lodash/debounce";

  export default {
    data() {
      return {
        logData: null,
        search: {
          page: 1,
          per: 25
        }
      };
    },

    computed: {
      logs() {
        if (this.logData) {
          return this.logData.logs;
        } else {
          return [];
        }
      },

      totalPages() {
        if (this.logData) {
          return this.logData.total_pages
        }
        return 0;
      },

      currentPage() {
        if (this.logData) {
          return this.logData.current_page
        }
        return 0;
      }
    },

    methods: {
      changePage(idx) {
        this.search.page = idx;
      },

      getList: debounce(function() {
        this.loadResource(
          api.getLogList(this.search.page, this.search.per)
            .then(data => this.logData = data)
        );
      }, 500, {leading: true, trailing: true})
    },

    created() {
      this.$watch("search",
        () => this.getList(),
        {
          deep: true,
          immediate: true
        }
      );
    }
  }

</script>

<style lang="scss" scoped>
</style>