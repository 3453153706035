<template>
  <div>
    <h1 class="title">Recipes</h1>

    <router-link v-if="isLoggedIn" :to="{name: 'new_recipe'}" class="button is-primary">Create Recipe</router-link>

    <app-pager :current-page="currentPage" :total-pages="totalPages" paged-item-name="recipe" @changePage="changePage"></app-pager>

    <app-loading v-if="localLoading"></app-loading>




    <table class="table is-fullwidth" :class="{ small: mediaQueries.touch }">
      <thead>
      <tr>
        <th v-for="h in tableHeader" :key="h.name">
          <a v-if="h.sort" href="#" @click.prevent="setSort(h.name)">
            {{h.label}}
            <app-icon v-if="search.column === h.name" size="sm" :icon="search.direction === 'asc' ? 'caret-bottom' : 'caret-top'"></app-icon>
          </a>
          <span v-else>{{h.label}}</span>
        </th>
        <th></th>
      </tr>
      <tr>
        <td>
          <app-search-text placeholder="search names" :value="search.name" @input="setSearchName($event)"></app-search-text>
        </td>
        <td>
          <app-search-text placeholder="search tags" :value="search.tags" @input="setSearchTags($event)"></app-search-text>
        </td>
        <td colspan="5"></td>
      </tr>
      </thead>
      <transition-group name="fade" tag="tbody">
      <tr v-for="r in recipes" :key="r.id">
        <td><router-link :to="{name: 'recipe', params: { id: r.id } }">{{r.name}}</router-link></td>
        <td>
          <div class="tags">
            <span class="tag" v-for="tag in r.tags" :key="tag">{{tag}}</span>
          </div>
        </td>
        <td>
          <app-rating v-if="r.rating !== null" :value="r.rating" readonly></app-rating>
          <span v-else>--</span>
        </td>
        <td>{{ r.yields }}</td>
        <td class="recipe-time">{{ formatRecipeTime(r.total_time, r.active_time) }}</td>
        <td><app-date-time :date-time="r.created_at" :show-time="false"></app-date-time></td>
        <td>
          <app-dropdown hover v-if="isLoggedIn" class="is-right">
            <button slot="button" class="button is-small">
              <app-icon icon="menu"></app-icon>
            </button>

            <div class="dropdown-item">
              <router-link :to="{name: 'new_log', params: { recipeId: r.id } }" class="button is-primary is-fullwidth">
                <app-icon icon="star" size="md"></app-icon> <span>Add Log Entry</span>
              </router-link>
            </div>

            <div class="dropdown-item">
              <router-link :to="{name: 'edit_recipe', params: { id: r.id } }" class="button is-primary is-fullwidth">
                <app-icon icon="pencil" size="md"></app-icon> <span>Edit Recipe</span>
              </router-link>
            </div>

            <div class="dropdown-item">
              <button type="button" class="button is-danger is-fullwidth" @click="deleteRecipe(r)">
                <app-icon icon="x" size="md"></app-icon> <span>Delete Recipe</span>
              </button>
            </div>

          </app-dropdown>
        </td>
      </tr>
      </transition-group>
    </table>

    <div v-if="!localLoading && recipes.length === 0">
      No Recipes
    </div>

    <app-pager :current-page="currentPage" :total-pages="totalPages" paged-item-name="recipe" @changePage="changePage"></app-pager>
    <app-confirm :open="showConfirmRecipeDelete" :message="confirmRecipeDeleteMessage" :cancel="recipeDeleteCancel" :confirm="recipeDeleteConfirm"></app-confirm>

  </div>
</template>

<script>

  import api from "../lib/Api";
  import debounce from "lodash/debounce";
  import { mapMutations, mapState } from "vuex";
  import AppLoading from "./AppLoading";

  export default {
    props: {
      searchQuery: {
        type: Object,
        required: false,
        default: {}
      }
    },

    data() {
      return {
        recipeData: null,
        recipeForDeletion: null
      };
    },

    computed: {
      ...mapState([
        "mediaQueries"
      ]),

      search() {
        return {
          name: this.searchQuery.name || null,
          tags: this.searchQuery.tags || null,
          column: this.searchQuery.column || "created_at",
          direction: this.searchQuery.direction || "desc",
          page: this.searchQuery.page || 1,
          per: this.searchQuery.per || 25
        }
      },

      recipes() {
        if (this.recipeData) {
          return this.recipeData.recipes;
        } else {
          return [];
        }
      },

      tableHeader() {
        return [
          {name: 'name', label: 'Name', sort: true},
          {name: 'tags', label: 'Tags', sort: false},
          {name: 'rating', label: 'Rating', sort: true},
          {name: 'yields', label: 'Yields', sort: false},
          {name: 'total_time', label: 'Time', sort: true},
          {name: 'created_at', label: 'Created', sort: true}
        ]
      },

      totalPages() {
        if (this.recipeData) {
          return this.recipeData.total_pages;
        }
        return 0;
      },

      currentPage() {
        if (this.recipeData) {
          return this.recipeData.current_page;
        }
        return 0;
      },

      showConfirmRecipeDelete() {
        return this.recipeForDeletion !== null;
      },

      confirmRecipeDeleteMessage() {
        if (this.showConfirmRecipeDelete) {
          return `Are you sure you want to delete ${this.recipeForDeletion.name}?`;
        } else {
          return "??";
        }
      }
    },

    methods: {
      ...mapMutations([
        "setInitialLoad"
      ]),
      
      buildQueryParams() {
        return {
          name: this.searchQuery.name,
          tags: this.searchQuery.tags,
          column: this.searchQuery.column,
          direction: this.searchQuery.direction,
          page: this.searchQuery.page,
          per: this.searchQuery.per
        }
      },

      redirectToParams(params) {
        const rParams = {};

        if (params.name) {
          rParams.name = params.name;
        }

        if (params.tags) {
          rParams.tags = params.tags;
        }

        if (params.column) {
          rParams.column = params.column;
        }

        if (params.direction) {
          rParams.direction = params.direction;
        }

        if (params.page) {
          rParams.page = params.page;
        }

        if (params.per) {
          rParams.per = params.per;
        }

        this.$router.push({name: 'recipeList', query: rParams});
      },

      changePage(idx) {
        const p = this.buildQueryParams();
        p.page = idx;
        this.redirectToParams(p);
      },

      setSort(col) {
        const p = this.buildQueryParams();

        if (p.column === col) {
          p.direction = p.direction === "desc" ? "asc" : "desc";
        } else {
          p.column = col;
          p.direction = "asc";
        }
        this.redirectToParams(p);
      },

      setSearchName(name) {
        const p = this.buildQueryParams();
        if (name !== p.name) {
          p.name = name;
          p.page = null;
          this.redirectToParams(p);
        }
      },

      setSearchTags(tags) {
        const p = this.buildQueryParams();
        if (tags !== p.tags) {
          p.tags = tags;
          p.page = null;
          this.redirectToParams(p);
        }
      },

      deleteRecipe(recipe) {
        this.recipeForDeletion = recipe;
      },

      recipeDeleteConfirm() {
        if (this.recipeForDeletion !== null) {
          this.loadResource(
            api.deleteRecipe(this.recipeForDeletion.id).then(() => {
              this.recipeForDeletion = null;
              return this.getList();
            })
          );
        }
      },

      recipeDeleteCancel() {
        this.recipeForDeletion = null;
      },

      getList() {
        return this.loadResource(
          api.getRecipeList(this.search.page, this.search.per, this.search.column, this.search.direction, this.search.name, this.search.tags, data => this.recipeData = data)
        );
      },

      formatRecipeTime(total, active) {
        let str = "";

        if (total && total > 0) {
          str += total;
        }

        if (active && active > 0) {
          if (str.length) {
            str += " (" + active + ")";
          } else {
            str += active;
          }
        }

        return str;
      }
    },

    created() {
      this.$watch("search",
        () => {
          this.getList().then(() => this.setInitialLoad(true));
        },
        {
          deep: true,
          immediate: true
        }
      );
    },

    components: {
      AppLoading
    }
  }

</script>

<style lang="scss" scoped>
  .recipe-time {
    white-space: nowrap;
  }

  .table th {
    white-space: nowrap;
  }

  .table.small {
    td, th {
      &:nth-of-type(3), &:nth-of-type(4), &:nth-of-type(5), &:nth-of-type(6) {
        display: none;
      }
    }
  }

</style>