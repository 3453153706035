<template>
  <div>
    <div class="field">
      <label class="label">Note</label>
      <div class="control">
        <textarea class="textarea" v-model="note.content"></textarea>
      </div>
    </div>

    <div class="buttons">
      <button type="button" class="button is-primary" :disabled="!canSave" @click="save">
        Save
      </button>

      <button type="button" class="button is-secondary" @click="cancel">
        Cancel
      </button>
    </div>
  </div>
</template>

<script>

  export default {
    props: {
      note: {
        required: true,
        type: Object
      }
    },

    data() {
      return {
      };
    },

    computed: {
      canSave() {
        return this.note && this.note.content && this.note.content.length;
      }
    },

    methods: {
      save() {
        this.$emit("save", this.note);
      },

      cancel() {
        this.$emit("cancel");
      }
    }
  }

</script>

<style lang="scss" scoped>
</style>