<template>
  <div>
    <h1 class="title">Ingredients</h1>

    <div class="buttons">
      <router-link v-if="isLoggedIn" :to="{name: 'new_food'}" class="button is-primary">Create Ingredient</router-link>
    </div>

    <app-pager :current-page="currentPage" :total-pages="totalPages" paged-item-name="food" @changePage="changePage"></app-pager>

    <table class="table is-fullwidth is-narrow">
      <thead>
      <tr>
        <th>Name</th>
        <th>USDA</th>
        <th>KCal per 100g</th>
        <th>Density (oz/cup)</th>
        <th></th>
      </tr>
      <tr>
        <th>
          <div class="field">
            <div class="control">
              <input type="text" class="input" placeholder="search names" v-model="search.name">
            </div>
          </div>
        </th>
        <th colspan="4"></th>
      </tr>
      </thead>
      <transition-group tag="tbody" name="fade" mode="out-in">
      <tr v-for="i in foods" :key="i.id">
        <td><router-link :to="{name: 'food', params: { id: i.id } }">{{i.name}}</router-link></td>
        <td><app-icon v-if="i.usda" icon="check"></app-icon></td>
        <td>{{i.kcal}}</td>
        <td>{{i.density}}</td>
        <td>
          <router-link v-if="isLoggedIn" class="button" :to="{name: 'edit_food', params: { id: i.id } }">
            <app-icon icon="pencil"></app-icon>
          </router-link>
          <button v-if="isLoggedIn" type="button" class="button is-danger" @click="deleteFood(i)">
            <app-icon icon="x"></app-icon>
          </button>
        </td>
      </tr>
      </transition-group>
    </table>

    <app-pager :current-page="currentPage" :total-pages="totalPages" paged-item-name="food" @changePage="changePage"></app-pager>

    <div class="buttons">
      <router-link v-if="isLoggedIn" :to="{name: 'new_food'}" class="button is-primary">Create Ingredient</router-link>
    </div>

    <app-confirm :open="showConfirmFoodDelete" :message="confirmFoodDeleteMessage" :cancel="foodDeleteCancel" :confirm="foodDeleteConfirm"></app-confirm>

  </div>
</template>

<script>

  import api from "../lib/Api";
  import debounce from "lodash/debounce";

  export default {
    data() {
      return {
        foodData: null,
        foodForDeletion: null,
        search: {
          page: 1,
          per: 25,
          name: null
        }
      };
    },

    computed: {
      foods() {
        if (this.foodData) {
          return this.foodData.foods;
        } else {
          return [];
        }
      },

      totalPages() {
        if (this.foodData) {
          return this.foodData.total_pages
        }
        return 0;
      },

      currentPage() {
        if (this.foodData) {
          return this.foodData.current_page
        }
        return 0;
      },

      showConfirmFoodDelete() {
        return this.foodForDeletion !== null;
      },

      confirmFoodDeleteMessage() {
        if (this.foodForDeletion !== null) {
          return `Are you sure you want to delete ${this.foodForDeletion.name}?`;
        } else {
          return "??";
        }
      }
    },

    methods: {
      changePage(idx) {
        this.search.page = idx;
      },

      getList: debounce(function() {
        return this.loadResource(
          api.getFoodList(this.search.page, this.search.per, this.search.name)
            .then(data => this.foodData = data)
        );
      }, 500, {leading: true, trailing: true}),

      deleteFood(food) {
        this.foodForDeletion = food;
      },

      foodDeleteCancel() {
        this.foodForDeletion = null;
      },

      foodDeleteConfirm() {
        if (this.foodForDeletion !== null) {
          this.loadResource(
            api.deleteFood(this.foodForDeletion.id).then(res => {
              this.foodForDeletion = null;
              return this.getList();
            })
          );

          console.log("This is where the thing happens!!");
          this.foodForDeletion = null;
        }
      }
    },

    created() {
      this.$watch("search",
        () => this.getList(),
        {
          deep: true,
          immediate: true
        }
      );
    },

    components: {
    }
  }

</script>