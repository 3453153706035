import Vue from 'vue'
import Vuex from 'vuex'

import api from '../lib/Api';
import { createChannel } from '../lib/ActionCable';

Vue.use(Vuex);

let taskChannel = null;

export default new Vuex.Store({
  strict: process.env.NODE_ENV !== 'production',
  state: {
    initialLoad: false,
    updateAvailable: false,
    loadingCount: 0,
    error: null,
    authChecked: false,
    user: null,
    loginMessage: null,

    taskLists: [],
    currentTaskList: null,

    nutrientList: {
      kcal: { label: "Calories", unit: "kcal" },
      protein: { label: "Protein", unit: "g" },
      lipids: { label: "Fat", unit: "g" },
      carbohydrates: { label: "Carbohydrates", unit: "g" },
      water: { label: "Water", unit: "g" },
      sugar: { label: "Sugar", unit: "g" },
      fiber: { label: "Fiber", unit: "g" },
      cholesterol: { label: "Cholesterol", unit: "mg" },
      sodium: { label: "Sodium", unit: "mg" },
      calcium: { label: "Calcium", unit: "mg" },
      iron: { label: "Iron", unit: "mg" },
      magnesium: { label: "Magnesium", unit: "mg" },
      phosphorus: { label: "Phosphorus", unit: "mg" },
      potassium: { label: "Potassium", unit: "mg" },
      zinc: { label: "Zinc", unit: "mg" },
      copper: { label: "Copper", unit: "mg" },
      manganese: { label: "Manganese", unit: "mg" },
      vit_a: { label: "Vitamin A", unit: "μg" },
      vit_b6: { label: "Vitamin B6", unit: "mg" },
      vit_b12: { label: "Vitamin B12", unit: "μg" },
      vit_c: { label: "Vitamin C", unit: "mg" },
      vit_d: { label: "Vitamin D", unit: "μg" },
      vit_e: { label: "Vitamin E", unit: "mg" },
      vit_k: { label: "Vitamin K", unit: "μg" },
      ash: { label: "ash", unit: "g" }

    }
  },
  getters: {
    isLoading(state) {
      return state.loadingCount > 0;
    },
    isLoggedIn(state) {
      return state.user !== null;
    },
    isAdmin(state) {
      return state.user !== null && state.user.admin === true;
    }
  },
  mutations: {
    setUpdateAvailable(state, value) {
      state.updateAvailable = value;
    },

    setInitialLoad(state, value) {
      state.initialLoad = value;
    },

    setLoading(state, value) {
      if (value) {
        state.loadingCount = state.loadingCount + 1;
      } else {
        state.loadingCount = state.loadingCount - 1;
      }
      state.loading = state.loadingCount !== 0;
    },

    setError(state, value) {
      console.log(value);
      state.error = value;
    },

    setUser(state, user) {
      state.authChecked = true;
      state.user = user;
    },

    setLoginMessage(state, msg) {
      state.loginMessage = msg;
    },

    setTaskLists(state, lists) {
      state.taskLists = lists || [];
    },

    setCurrentTaskList(state, list) {
      state.currentTaskList = list || null;
    },

    replaceTaskList(state, list) {
      if (state.taskLists) {
        const listIdx = state.taskLists.findIndex(l => l.id === list.id);
        if (listIdx >= 0) {
          state.taskLists.splice(listIdx, 1, list);
        }
        if (state.currentTaskList && state.currentTaskList.id === list.id) {
          state.currentTaskList = list;
        }
      }
    }
  },
  actions: {
    updateCurrentUser({commit}) {
      return api.getCurrentUser()
        .then(user => {
          commit("setUser", user);
          return user;
        });
    },

    login({commit}, authData) {
      return api.postLogin(authData.username, authData.password)
        .then(data => {
          if (data.success) {
            commit("setUser", data.user);
            commit("setLoginMessage", null);
          } else {
            commit("setUser", null);
            commit("setLoginMessage", data.message);
          }
          return data;
        });
    },

    logout({commit}) {
      return api.getLogout()
        .then(() => {
          commit("setUser", null);
        });
    },

    refreshTaskLists({commit, dispatch, state}) {
      const cb = function(data) {
        commit("setTaskLists", data);
        let ctl = null;

        if (state.currentTaskList) {
          ctl = data.find(l => l.id === state.currentTaskList.id);
        }

        ctl = ctl || data[0] || null;
        commit("setCurrentTaskList", ctl);
        dispatch('ensureTaskListChannel');
      };

      return api.getTaskLists(cb)
    },

    ensureTaskLists({dispatch, state}) {
      if (state.user && state.taskLists.length === 0) {
        return dispatch("refreshTaskLists");
      } else {
        return Promise.resolve();
      }
    },

    ensureTaskListChannel({ commit }) {
      if (taskChannel === null) {
        taskChannel = createChannel(null, "TaskChannel", {
          received(data) {
            if (data && data.action === 'updated') {
              commit('replaceTaskList', data.task_list);
            }
          }
        });
      }
    },

    createTaskList({commit, dispatch}, newList) {
      return api.postTaskList(newList)
        .then(data => commit("setCurrentTaskList", data))
        .then(() => dispatch("refreshTaskLists"))
    },

    deleteTaskList({dispatch}, taskList) {
      return api.deleteTaskList(taskList)
        .then(() => dispatch("refreshTaskLists"));
    },

    createTaskItem({commit, dispatch}, taskItem) {

      return api.postTaskItem(taskItem.task_list_id, taskItem)
        .then(data => {
          return data;
        });
    },

    updateTaskItem({commit}, taskItem) {
      return api.patchTaskItem(taskItem.task_list_id, taskItem)
        .then(data => {
          return data;
        });
    },

    deleteTaskItems({commit}, payload) {
      return api.deleteTaskItems(payload.taskList.id, payload.taskItems);
    },

    completeTaskItems({commit}, payload) {
      return api.completeTaskItems(payload.taskList.id, payload.taskItems, !payload.completed);
    }
  }
});
