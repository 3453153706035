<template>
  <span>
    <input v-if="useInput" class="text" type="text" readonly :value="friendlyString" />
    <span v-else :title="fullString">{{ friendlyString }}</span>
  </span>
</template>

<script>

  import DateTimeUtils from "../lib/DateTimeUtils";

  export default {
    props: {
      dateTime: {
        required: true,
        type: [Date, String]
      },

      showDate: {
        required: false,
        type: Boolean,
        default: true
      },

      showTime: {
        required: false,
        type: Boolean,
        default: true
      },

      useInput: {
        required: false,
        type: Boolean,
        default: false
      }
    },

    computed: {
      dateObj() {
        return DateTimeUtils.toDate(this.dateTime);
      },

      friendlyString() {
        const parts = [];
        if (this.showDate) {
          parts.push(DateTimeUtils.formatDate(this.dateObj));
        }
        if (this.showTime) {
          parts.push(DateTimeUtils.formatTime(this.dateObj, true));
        }
        return parts.join(" ");
      },

      fullString() {
        return DateTimeUtils.formatTimestamp(this.dateObj);
      }
    }
  }

</script>

<style lang="scss" scoped>
</style>