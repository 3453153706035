
export function ApiError(message, response) {
  this.message = (message || "Unknown API Error Occurred");
  this.response = response;
}
ApiError.prototype = Object.assign(new Error(), {
  name: "ApiError",

  responseCode: function() {
    if (this.response) {
      return this.response.status;
    } else {
      return null;
    }
  }
});

export function ApiServerError(message, response) {
  this.message = (message || "Unknown API Server Error Occurred");
  this.response = response;
}
ApiServerError.prototype = Object.assign(new ApiError(), {
  name: "ApiServerError"
});


export function ApiNotFoundError(message, response) {
  this.message = (message || "Unknown API Server Error Occurred");
  this.response = response;
}
ApiNotFoundError.prototype = Object.assign(new ApiError(), {
  name: "ApiNotFoundError"
});


export function ApiValidationError(message, response, json) {
  this.message = (message || "Server returned a validation error");
  this.response = response;
  this.json = json;
}
ApiValidationError.prototype = Object.assign(new ApiError(), {
  name: "ApiValidationError",

  validationErrors: function() {
    const errors = {};
    if (this.json) {
      for (let key in this.json) {
        errors[key] = this.json[key];
      }
    } else {
      errors["base"] = ["unknown error"];
    }
    return errors;
  },

  formattedValidationErrors: function() {
    const errors = [];
    if (this.json) {
      for (let key in this.json) {
        errors.push(key + ": " + this.json[key].join(", "));
      }
      return errors;
    } else {
      return ["unable to determine validation errors"];
    }
  }
});


export function onlyFor(errorType, handler) {
  return (err) => {
    if (err instanceof errorType) {
      return handler(err);
    } else {
      return Promise.reject(err);
    }
  };
}

