<template>
  <div>

    <food-edit :food="food" :validation-errors="validationErrors" action="Creating"></food-edit>

    <button type="button" class="button is-primary" @click="save">Save</button>
    <router-link class="button is-secondary" to="/food">Cancel</router-link>

  </div>
</template>

<script>

  import FoodEdit from "./FoodEdit";
  import { mapState } from "vuex";
  import api from "../lib/Api";
  import * as Errors from '../lib/Errors';

  export default {
    data() {
      return {
        food: {
          name: null,
          notes: null,
          ndbn: null,
          density: null,
          water: null,
          ash: null,
          protein: null,
          kcal: null,
          fiber: null,
          sugar: null,
          carbohydrates: null,
          calcium: null,
          iron: null,
          magnesium: null,
          phosphorus: null,
          potassium: null,
          sodium: null,
          zinc: null,
          copper: null,
          manganese: null,
          vit_c: null,
          vit_b6: null,
          vit_b12: null,
          vit_a: null,
          vit_e: null,
          vit_d: null,
          vit_k: null,
          cholesterol: null,
          lipids: null,
          food_units: []
        },
        validationErrors: {}
      }
    },

    methods: {
      save() {
        this.validationErrors = {}
        this.loadResource(
          api.postFood(this.food)
            .then(() => this.$router.push('/foods'))
            .catch(Errors.onlyFor(Errors.ApiValidationError, err => this.validationErrors = err.validationErrors()))
        );
      }
    },

    components: {
      FoodEdit
    }
  }

</script>

<style lang="scss" scoped>
</style>