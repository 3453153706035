import * as cable from "@rails/actioncable";

let consumer = null;

function createChannel(baseUrl, ...args) {
  if (consumer === null) {
    if (baseUrl !== null && baseUrl.toString() !== "") {
      consumer = cable.createConsumer(baseUrl);
    } else {
      consumer = cable.createConsumer();
    }
  }

  return consumer.subscriptions.create(...args);
}

export { createChannel };
