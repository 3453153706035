<template>
  <div>

    <div v-if="food === null">
      Loading...
    </div>
    <div v-else>
      <food-edit :food="food" :validation-errors="validationErrors"></food-edit>
    </div>

    <button type="button" class="button is-primary" @click="save">Save</button>
    <router-link class="button is-secondary" to="/foods">Cancel</router-link>

  </div>
</template>

<script>

  import FoodEdit from "./FoodEdit";
  import { mapState } from "vuex";
  import api from "../lib/Api";
  import * as Errors from '../lib/Errors';

  export default {
    data: function () {
      return {
        food: null,
        validationErrors: {}
      };
    },

    computed: {
      ...mapState({
        foodId: state => state.route.params.id,
      })
    },

    methods: {
      save() {
        this.validationErrors = {};
        this.loadResource(
          api.patchFood(this.food)
            .then(() => this.$router.push({name: 'food', params: {id: this.foodId }}))
            .catch(Errors.onlyFor(Errors.ApiValidationError, err => this.validationErrors = err.validationErrors()))
        );
      }
    },

    created() {
      this.loadResource(
        api.getFood(this.foodId)
          .then(data => { this.food = data; return data; })
      );
    },

    components: {
      FoodEdit
    }
  }

</script>

<style lang="scss" scoped>
</style>