<template>

  <div>

    <h1 class="title">Creating Log for {{ log.recipe.name }}</h1>

    <app-validation-errors :errors="validationErrors"></app-validation-errors>

    <div class="columns">
      <div class="column">
        <app-date-picker v-model="log.date" label="Date"></app-date-picker>
      </div>

      <div class="column">
        <div class="field">
          <label class="label is-small-mobile">Rating</label>
          <div class="control">
            <app-rating v-model="log.rating" :step="1"></app-rating>
          </div>
        </div>
      </div>
    </div>

    <app-text-field label="Notes" v-model="log.notes" type="textarea"></app-text-field>

    <slot></slot>

    <recipe-edit :recipe="log.recipe" :for-logging="true"></recipe-edit>

  </div>

</template>

<script>

  import RecipeEdit from "./RecipeEdit";

  export default {
    props: {
      log: {
        required: true,
        type: Object
      },
      validationErrors: {
        required: false,
        type: Object,
        default: {}
      },
    },

    components: {
      RecipeEdit
    }
  }

</script>

<style lang="scss" scoped>
</style>