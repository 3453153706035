<template>
  <div>
    <div v-if="food === null">
      Loading...
    </div>
    <div v-else>
      <food-show :food="food"></food-show>
    </div>

    <router-link v-if="isLoggedIn" class="button" :to="{name: 'edit_food', params: { id: foodId }}">Edit</router-link>
    <router-link class="button" to="/foods">Back</router-link>
  </div>
</template>

<script>

  import FoodShow from "./FoodShow";
  import { mapState } from "vuex";
  import api from "../lib/Api";

  export default {
    data: function () {
      return {
        food: null
      }
    },

    computed: {
      ...mapState({
        foodId: state => state.route.params.id,
      })
    },

    created() {
      this.loadResource(
        api.getFood(this.foodId)
          .then(data => { this.food = data; return data; })
      );
    },

    components: {
      FoodShow
    }
  }

</script>

<style lang="scss" scoped>
</style>