<template>
  <div>
    <h1 class="title">[Log Entry] {{log.recipe.name}}</h1>

    <div class="columns">
      <div class="column is-half-tablet">
        <div class="field is-horizontal">
          <div class="field-label is-normal">
            <label class="label">Date</label>
          </div>
          <div class="field-body">
            <div class="field">
              <app-date-time use-input :show-time="false" :date-time="log.date"></app-date-time>
            </div>
          </div>
        </div>

        <div class="field is-horizontal">
          <div class="field-label is-normal">
            <label class="label">Rating</label>
          </div>
          <div class="field-body">
            <div class="field">
              <app-rating readonly :value="log.rating"></app-rating>
            </div>
          </div>
        </div>

        <div class="field is-horizontal">
          <div class="field-label is-normal">
            <label class="label">Notes</label>
          </div>
          <div class="field-body">
            <div class="field">
              <textarea readonly class="textarea" :value="log.notes"></textarea>
            </div>
          </div>
        </div>
      </div>
    </div>

    <recipe-show :recipe="log.recipe"></recipe-show>

  </div>
</template>

<script>

  import RecipeShow from "./RecipeShow";

  export default {
    props: {
      log: {
        required: true,
        type: Object
      }
    },

    components: {
      RecipeShow
    }
  }

</script>

<style lang="scss" scoped>
</style>