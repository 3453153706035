<template>
  <div>
    <app-text-field label="Username" v-model="userObj.username"></app-text-field>
    <app-text-field label="Name" v-model="userObj.full_name"></app-text-field>
    <app-text-field label="Email" v-model="userObj.email"></app-text-field>
    <app-text-field label="Password" v-model="userObj.password"></app-text-field>
    <app-text-field label="Password Confirmation" v-model="userObj.password_confirmation"></app-text-field>
  </div>
</template>

<script>

  export default {
    props: {
      userObj: {
        required: true,
        type: Object
      }
    }
  }

</script>

<style lang="scss" scoped>

</style>