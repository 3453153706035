<template>
  <div>
    <div class="notification is-danger" v-if="errors !== null" v-for="(errs, prop) in errors">
      {{ prop }}: {{ errs.join(", ") }}
    </div>
  </div>
</template>

<script>

  export default {
    props: {
      errors: {
        required: false,
        type: Object,
        default: {}
      }
    }
  }

</script>