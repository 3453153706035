import { render, staticRenderFns } from "./TheFood.vue?vue&type=template&id=718d5c3c&scoped=true&"
import script from "./TheFood.vue?vue&type=script&lang=js&"
export * from "./TheFood.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "718d5c3c",
  null
  
)

export default component.exports