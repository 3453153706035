<template>
  <div>
    <h1 class="title">Calculator</h1>

    <div class="box">

      <div class="columns">
        <app-text-field label="Input" v-model="input" class="column" :validation-error="inputErrors"></app-text-field>
        <app-text-field label="Output Unit" v-model="outputUnit" class="column" :validation-error="outputUnitErrors"></app-text-field>

      </div>

      <div class="columns">
        <div class="field column">
          <label class="label">Ingredient</label>
          <div class="control">
            <app-autocomplete
                :inputClass="{'is-success': ingredient !== null}"
                ref="autocomplete"
                v-model="ingredient_name"
                :minLength="2"
                valueAttribute="name"
                labelAttribute="name"
                placeholder=""
                @optionSelected="searchItemSelected"
                :onGetOptions="updateSearchItems"
            >
            </app-autocomplete>
          </div>
        </div>

        <app-text-field label="Density" v-model="density" class="column" :disabled="ingredient !== null" :validation-error="densityErrors"></app-text-field>

      </div>

      <app-text-field label="Output" v-model="output" disabled></app-text-field>

    </div>

  </div>
</template>

<script>

  import api from "../lib/Api";
  import debounce from "lodash/debounce";

  export default {
    data() {
      return {
        input: '',
        outputUnit: '',
        ingredient_name: '',
        ingredient: null,
        density: '',
        output: '',
        errors: {}
      };
    },

    computed: {
      inputErrors() {
        if (this.errors.input && this.errors.input.length > 0) {
          return this.errors.input.join(", ");
        } else {
          return null;
        }
      },

      outputUnitErrors() {
        if (this.errors.output_unit && this.errors.output_unit.length > 0) {
          return this.errors.output_unit.join(", ");
        } else {
          return null;
        }
      },

      densityErrors() {
        if (this.errors.density && this.errors.density.length > 0) {
          return this.errors.density.join(", ");
        } else {
          return null;
        }
      }
    },

    methods: {
      updateSearchItems(text) {
        return api.getSearchIngredients(text);
      },

      searchItemSelected(ingredient) {
        this.ingredient = ingredient || null;
        this.ingredient_name = ingredient.name || null;
        this.density = ingredient.density || null;
      },

      updateOutput: debounce(function() {
        if (this.input && this.input.length > 0) {
          this.loadResource(api.getCalculate(this.input, this.outputUnit, this.ingredient ? this.ingredient.ingredient_id : null, this.density)
            .then(data => {
              this.output = data.output;
              this.errors = data.errors;
            })
          );
        }
      }, 500)
    },

    watch: {
      'ingredient_name': function(val) {
        if (this.ingredient && this.ingredient.name !== val) {
          this.ingredient = null;
        }
      }
    },

    created() {
      this.$watch(
        function() {
          return [this.input, this.outputUnit, this.density, this.ingredient];
        },
        function() {
          this.updateOutput();
        }
      )
    },

    components: {
    }
  }

</script>

<style lang="scss" scoped>
</style>