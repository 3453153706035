<template>

  <div class="dropdown-item" @mouseover="hovering = true" @mouseleave="hovering = false" :class="{hovered: hovering, 'is-active': active}" @click="selectList">
    <span>{{taskList.name}} ({{ taskList.task_items.length }})</span>
    <button @click.stop="confirmingDelete = true" class="button is-small is-danger is-pulled-right"><app-icon icon="x" size="sm"></app-icon></button>
    <div class="is-clearfix"></div>

    <app-modal :open="confirmingDelete" :title="'Delete ' + taskList.name + '?'" @dismiss="confirmingDelete = false">
      <button class="button is-danger" @click="deleteList">Confirm</button>
      <button class="button is-primary" @click="confirmingDelete = false">Cancel</button>
    </app-modal>

  </div>

</template>

<script>

  export default {
    props: {
      taskList: {
        type: Object,
        required: true
      },

      active: {
        type: Boolean,
        required: false,
        default: false
      }
    },

    data() {
      return {
        hovering: false,
        confirmingDelete: false
      };
    },

    methods: {
      selectList() {
        this.$emit("select", this.taskList);
      },

      deleteList() {
        this.confirmingDelete = false;
        this.$emit("delete", this.taskList);
      }
    }
  }

</script>

<style lang="scss" scoped>

  @import "~styles/variables";

  div.dropdown-item {
    cursor: pointer;

    &.hovered {
      color: $black;
      background-color: $background;
    }

    &.is-active {
      color: $link-invert;
      background-color: $link;
    }
  }

</style>