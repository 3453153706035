<template>
  <div>
    <h1 class="title is-3">
      Tasks
      <app-dropdown button-class="is-primary" :open="showListDropdown" :label="listSelectLabel" @open="showListDropdown = true" @close="showListDropdown = false">

        <task-list-dropdown-item v-for="l in taskLists" :key="l.id" :task-list="l" :active="currentTaskList !== null && currentTaskList.id === l.id" @select="selectList" @delete="deleteList"></task-list-dropdown-item>

        <hr class="dropdown-divider" v-if="taskLists.length > 0">
        <div class="dropdown-item">
          <task-list-mini-form :task-list="newList" :validation-errors="newListValidationErrors" @save="saveNewList"></task-list-mini-form>
        </div>
      </app-dropdown>
    </h1>

    <div class="columns" v-if="currentTaskList !== null">
      <div class="column is-6-widescreen is-8-desktop is-10-tablet">
        <task-item-list :task-list="currentTaskList"></task-item-list>
      </div>
    </div>

  </div>
</template>

<script>

  import api from "../lib/Api";
  import * as Errors from '../lib/Errors';
  import { mapActions, mapMutations, mapState } from "vuex";

  import TaskListMiniForm from "./TaskListMiniForm";
  import TaskListDropdownItem from "./TaskListDropdownItem";
  import TaskItemList from "./TaskItemList";

  const newListTemplate = function() {
    return {
      name: ''
    };
  };

  export default {
    data() {
      return {
        showListDropdown: false,
        newList: newListTemplate(),
        newListValidationErrors: {}
      }
    },

    computed: {
      ...mapState([
        'taskLists',
        'currentTaskList'
      ]),
      listSelectLabel() {
        if (this.currentTaskList === null) {
          return "Select or Create a List";
        } else {
          return this.currentTaskList.name;
        }
      }
    },

    methods: {
      ...mapActions([
        'refreshTaskLists',
        'createTaskList',
        'deleteTaskList',
        'deleteTaskItems',
        'completeTaskItems'
      ]),
      ...mapMutations([
        'setCurrentTaskList'
      ]),

      selectList(list) {
        this.setCurrentTaskList(list);
        this.showListDropdown = false;
      },

      saveNewList() {
        this.loadResource(
          this.createTaskList(this.newList)
            .then(() => this.showListDropdown = false)
            .then(() => { this.newList = newListTemplate(); this.newListValidationErrors = {}; } )
            .catch(Errors.onlyFor(Errors.ApiValidationError, err => this.newListValidationErrors = err.validationErrors()))
        );
      },

      deleteList(list) {
        this.loadResource(
          this.deleteTaskList(list)
        );
      },



      deleteAllItems() {
        this.loadResource(
          this.deleteTaskItems({
            taskList: this.currentTaskList,
            taskItems: this.currentTaskList.task_items
          })
        );
      }
    },

    created() {
      this.loadResource(
        this.refreshTaskLists()
      );
    },

    components: {
      TaskListMiniForm,
      TaskListDropdownItem,
      TaskItemList
    }
  }

</script>

<style lang="scss" scoped>

</style>