<template>
  <div>
    <div v-if="recipe === null">
      Loading...
    </div>
    <div v-else>
      <h1 class="title">{{ recipe.name }}</h1>
      <div class="subtitle tags">
        <span v-for="tag in recipe.tags" :key="tag" class="tag is-medium">{{tag}}</span>
      </div>
      <div class="tags">
        <span v-if="isScaled" class="tag is-large">{{recipe.converted_scale}} X</span>
        <span v-if="recipe.converted_system !== null" class="tag is-large">{{recipe.converted_system}}</span>
        <span v-if="recipe.converted_unit !== null" class="tag is-large">{{recipe.converted_unit}}</span>
      </div>
      <hr>
      <recipe-show :recipe="recipe"></recipe-show>
    </div>

    <router-link v-if="isLoggedIn" class="button" :to="{name: 'edit_recipe', params: { id: recipeId }}">Edit</router-link>
    <router-link class="button" to="/">Back</router-link>
  </div>
</template>

<script>

  import RecipeShow from "./RecipeShow";
  import { mapState } from "vuex";
  import api from "../lib/Api";

  export default {
    data: function () {
      return {
        recipe: null,
        showNutrition: false
      }
    },

    computed: {
      ...mapState({
        recipeId: state => state.route.params.id,
        routeQuery: state => state.route.query,
        scale: state => state.route.query.scale || null,
        system: state => state.route.query.system || null,
        unit: state => state.route.query.unit || null
      }),

      isScaled() {
        return this.recipe.converted_scale !== null && this.recipe.converted_scale.length > 0 && this.recipe.converted_scale !== "1";
      }
    },

    watch: {
      routeQuery() {
        this.refreshData();
      },

      recipe(newRecipe) {
        if (newRecipe) {
          document.title = `${newRecipe.name}`;
        }
      }
    },

    methods: {
      refreshData() {
        this.loadResource(
          api.getRecipe(this.recipeId, this.scale, this.system, this.unit, data => this.recipe = data)
        );
      }
    },

    created() {
      this.refreshData();
    },

    components: {
      RecipeShow
    }
  }

</script>

<style lang="scss" scoped>
</style>
