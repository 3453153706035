<template>
  <div class="columns is-mobile edit-ingredient-item">
    <div class="column">

      <div class="columns is-multiline is-mobile">
        <div class="column is-half-mobile is-2-tablet">
          <span class="label is-small-mobile" v-if="showLabels">Quantity</span>
          <input class="input is-small-mobile" type="text" v-model="ingredient.quantity">
        </div>
        <div class="column is-half-mobile is-3-tablet">
          <span class="label is-small-mobile" v-if="showLabels">Units</span>
          <input class="input is-small-mobile"  type="text" v-model="ingredient.units">
        </div>
        <div class="column is-half-mobile is-3-tablet">
          <span class="label is-small-mobile" v-if="showLabels">Name</span>

          <app-autocomplete
              :inputClass="{'is-small-mobile': true, 'is-success': ingredient.ingredient_id !== null}"
              ref="autocomplete"
              v-model="ingredient.name"
              :minLength="2"
              valueAttribute="name"
              labelAttribute="name"
              placeholder=""
              @inputClick="nameClick"
              @optionSelected="searchItemSelected"
              :onGetOptions="updateSearchItems"
          >
          </app-autocomplete>
        </div>
        <div class="column is-half-mobile is-4-tablet">
          <span class="label is-small-mobile" v-if="showLabels">Preparation</span>
          <input class="input is-small-mobile"  type="text" v-model="ingredient.preparation">
        </div>
      </div>

    </div>
    <div class="column is-narrow">
      <span class="label is-small-mobile" v-if="showLabels">&nbsp;</span>
      <button type="button" class="button is-danger is-small" @click="deleteFood(ingredient)">
        <app-icon icon="x" size="md"></app-icon>
      </button>
    </div>
  </div>
</template>

<script>

  import api from "../lib/Api";

  export default {
    props: {
      ingredient: {
        required: true,
        type: Object
      },
      showLabels: {
        required: false,
        type: Boolean,
        default: false
      }
    },

    methods: {
      deleteFood(ingredient) {
        this.$emit("deleteFood", ingredient);
      },

      updateSearchItems(text) {
        return api.getSearchIngredients(text);
      },

      searchItemSelected(ingredient) {
        this.ingredient.ingredient_id = ingredient.id;
        this.ingredient.ingredient = ingredient;
        this.ingredient.name = ingredient.name;
      },

      nameClick() {
        if (this.ingredient.ingredient_id === null && this.ingredient.name !== null && this.ingredient.name.length > 2) {
          this.$refs.autocomplete.updateOptions(this.ingredient.name);
        }
      }
    },

    watch: {
      'ingredient.name': function(val) {
        if (this.ingredient.ingredient && this.ingredient.ingredient.name !== val) {
          this.ingredient.ingredient_id = null;
          this.ingredient.ingredient = null;
        }
      }
    },

    components: {
    }
  }

</script>

<style lang="scss" scoped>

  @import "../styles/variables";

  .edit-ingredient-item {
    border-bottom: solid 1px $grey-light;
    margin-bottom: 1.25rem;

    &:last-child {
      border-bottom: none;
    }
  }

</style>