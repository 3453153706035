<template>

  <div>

    <h1 class="title">User List</h1>

    <table class="table">
      <thead>
      <tr>
        <th>Name</th>
        <th>Email</th>
        <th>Admin?</th>
        <th></th>
      </tr>
      </thead>
      <tbody>
      <tr v-for="u in userList" :key="u.id">
        <td>{{u.name}}</td>
        <td>{{u.email}}</td>
        <td>{{u.admin}}</td>
        <td>
          <button type="button" class="button is-danger">X</button>
        </td>
      </tr>
      </tbody>
    </table>

  </div>

</template>

<script>

  import api from "../lib/Api";

  export default {
    data() {
      return {
        userList: []
      };
    },

    created() {
      this.loadResource(
        api.getAdminUserList()
          .then(list => this.userList = list)
      );
    }
  }

</script>

<style lang="scss" scoped>

</style>