<template>
  <div class="field">
    <div class="control">
      <input type="text" class="input" :placeholder="placeholder" :value="text === null ? '' : text" @input="userUpdateText($event.target.value)">
    </div>
  </div>
</template>

<script>

import debounce from "lodash/debounce";

export default {
  props: {
    placeholder: {
      required: false,
      type: String,
      default: ""
    },

    value: {
      required: false,
      type: String,
      default: ""
    }
  },

  data() {
    return {
      text: null
    };
  },

  computed: {

  },

  methods: {
    triggerInput: debounce(function() {
      this.$emit("input", this.text);
    }, 250, {leading: false, trailing: true}),

    userUpdateText(text) {
      if (this.text !== text) {
        this.text = text;
        this.triggerInput();
      }
    },

    propUpdateText(text) {
      if (this.text === null && this.text !== text) {
        this.text = text;
      }
    }
  },

  created() {
    this.$watch("value",
      val => this.propUpdateText(val),
      {
        immediate: true
      }
    );
  }
}

</script>