<template>
  <span ref="wrapper" class="rating" @click="handleClick" @mousemove="handleMousemove" @mouseleave="handleMouseleave">
    <span class="set empty-set">
      <app-iconic-icon v-for="i in starCount" :key="i" icon="star-empty" size="md"></app-iconic-icon>
    </span>
    <span class="set filled-set" :style="filledStyle">
      <app-iconic-icon v-for="i in starCount" :key="i" icon="star" size="md"></app-iconic-icon>
    </span>
  </span>
</template>

<script>


  export default {
    props: {
      starCount: {
        required: false,
        type: Number,
        default: 5
      },

      readonly: {
        required: false,
        type: Boolean,
        default: false
      },

      step: {
        required: false,
        type: Number,
        default: 0.5
      },

      value: {
        required: false,
        type: Number,
        default: 0
      }
    },

    data() {
      return {
        temporaryValue: null
      };
    },

    computed: {
      ratingPercent() {
        return ((this.value || 0) / this.starCount) * 100.0;
      },

      temporaryPercent() {
        if (this.temporaryValue !== null) {
          return (this.temporaryValue / this.starCount) * 100.0;
        } else {
          return null;
        }
      },

      filledStyle() {
        const width = this.temporaryPercent || this.ratingPercent;
        return {
          width: width + "%"
        };
      }
    },

    methods: {
      handleClick(evt) {
        if (this.temporaryValue !== null) {
          this.$emit("input", this.temporaryValue);
        }
      },

      handleMousemove(evt) {
        if (this.readonly) {
          return;
        }

        const wrapperBox = this.$refs.wrapper.getBoundingClientRect();
        const wrapperWidth = wrapperBox.right - wrapperBox.left;
        const mousePosition = evt.clientX;

        if (mousePosition > wrapperBox.left && mousePosition < wrapperBox.right) {
          const filledRatio = ((mousePosition - wrapperBox.left) / wrapperWidth);

          const totalSteps = this.starCount / this.step;
          const filledSteps = Math.round(totalSteps * filledRatio);

          this.temporaryValue = filledSteps * this.step;
        }
      },

      handleMouseleave(evt) {
        this.temporaryValue = null;
      }
    },

    components: {
    }
  }

</script>

<style lang="scss" scoped>

  @import "../styles/variables";

  span.rating {
    position: relative;
    display: inline-block;

    .set {
      white-space: nowrap;

      svg.iconic {
        width: 1.5em;
        height: 1.5em;
      }
    }

    .empty-set {
      color: gray;
    }

    .filled-set {
      color: $yellow;
      position: absolute;
      top: 0;
      left: 0;
      overflow-x: hidden;
    }
  }

</style>