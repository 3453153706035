<template>
  <div class="columns task-item-edit">

    <div class="field column">
      <label class="label is-small">Name</label>
      <div class="control">
        <input class="input is-small" type="text" placeholder="Name" v-model="taskItem.name" @keydown="inputKeydown" ref="nameInput">
      </div>
    </div>

    <div class="field column">
      <label class="label is-small">Quantity</label>
      <div class="control">
        <input class="input is-small" type="text" placeholder="Qty" v-model="taskItem.quantity" @keydown="inputKeydown">
      </div>
    </div>

    <div class="field column">
      <div class="control">
        <button class="button is-primary" @click="save">Add</button>
      </div>
    </div>

  </div>
</template>

<script>

  export default {
    props: {
      taskItem: {
        required: true,
        type: Object
      }
    },

    methods: {
      inputKeydown(evt) {
        switch (evt.key) {
          case "Enter":
            evt.preventDefault();
            this.save();
        }
      },

      save() {
        this.$emit("save", this.taskItem);
      },

      focus() {
        this.$refs.nameInput.focus();
      }
    },

    mounted() {
      this.focus();
    }
  }

</script>

<style lang="scss" scoped>

  .task-item-edit {

  }

</style>