import Vue from 'vue';
import Router from 'vue-router';

import The404Page from './components/The404Page';
import TheAboutPage from './components/TheAboutPage';
import TheCalculator from './components/TheCalculator';

import TheLog from './components/TheLog';
import TheLogList from './components/TheLogList';
import TheLogCreator from './components/TheLogCreator';
import TheLogEditor from './components/TheLogEditor';

import TheFoodList from './components/TheFoodList';
import TheFood from "./components/TheFood";
import TheFoodEditor from "./components/TheFoodEditor";
import TheFoodCreator from "./components/TheFoodCreator";
import TheNotesList from './components/TheNotesList';
import TheRecipe from './components/TheRecipe';
import TheRecipeEditor from './components/TheRecipeEditor';
import TheRecipeCreator from './components/TheRecipeCreator';
import TheRecipeList from './components/TheRecipeList';

import TheTaskListList from './components/TheTaskListList';

import TheUserCreator from './components/TheUserCreator';
import TheUserEditor from './components/TheUserEditor';

import TheAdminUserList from './components/TheAdminUserList';
import TheAdminUserEditor from './components/TheAdminUserEditor';

import $store from './store';

Vue.use(Router);

const router = new Router({
  routes: []
});

router.afterEach((to, from) => {
  if (to.meta.handleInitialLoad !== true && $store.state.initialLoad === false) {
    $store.commit("setInitialLoad", true);
  }

  Vue.nextTick(() => {
    document.title = to.meta.title || 'Parsley';
  });
});

router.addRoutes(
  [
    {
      path: '/',
      redirect: '/recipes'
    },
    {
      path: '/recipes',
      name: 'recipeList',
      component: TheRecipeList,
      props: route => ({
        searchQuery: {
          name: route.query.name,
          tags: route.query.tags,
          column: route.query.column,
          direction: route.query.direction,
          page: route.query.page,
          per: route.query.per
        }
      }),
      meta: {
        handleInitialLoad: true
      }
    },
    {
      path: '/recipes/new',
      name: 'new_recipe',
      component: TheRecipeCreator
    },
    {
      path: '/recipes/:id/edit',
      name: 'edit_recipe',
      component: TheRecipeEditor
    },
    {
      path: '/recipe/:id',
      name: 'recipe',
      component: TheRecipe
    },
    {
      path: "/about",
      name: "about",
      component: TheAboutPage
    },
    {
      path: "/calculator",
      name: "calculator",
      component: TheCalculator
    },
    {
      path: "/foods",
      name: "foods",
      component: TheFoodList
    },
    {
      path: "/foods/new",
      name: "new_food",
      component: TheFoodCreator
    },
    {
      path: "/foods/:id/edit",
      name: "edit_food",
      component: TheFoodEditor
    },
    {
      path: "/foods/:id",
      name: "food",
      component: TheFood
    },
    {
      path: "/logs",
      name: "logs",
      component: TheLogList
    },
    {
      path: "/recipes/:recipeId/logs/new",
      name: "new_log",
      component: TheLogCreator
    },
    {
      path: "/logs/:id/edit",
      name: "edit_log",
      component: TheLogEditor
    },
    {
      path: "/logs/:id",
      name: "log",
      component: TheLog
    },
    {
      path: "/notes",
      name: "notes",
      component: TheNotesList
    },
    {
      path: "/tasks",
      name: "task_lists",
      component: TheTaskListList
    },
    {
      path: "/logout",
      name: "logout",
      beforeEnter: (to, from, next) => {
        const $store = router.app.$store;
        $store.dispatch("logout")
          .then(() => next("/"));
      }
    },

    {
      path: "/user/new",
      name: "new_user",
      component: TheUserCreator
    },
    {
      path: "/user/edit",
      name: "edit_user",
      component: TheUserEditor
    },

    {
      path: "/admin/users",
      name: "admin_users",
      component: TheAdminUserList
    },

    {
      path: "/admin/users/:id/edit",
      name: "admin_edit_user",
      component: TheAdminUserEditor
    },

    {
      path: '*',
      component: The404Page
    }
  ]
);

export default router;
