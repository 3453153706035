<template>
  <div id="app">
    <vue-progress-bar></vue-progress-bar>
    <app-navbar></app-navbar>
    <section id="main" class="">
      <div class="container">
        <transition name="fade" mode="out-in">
          <router-view v-if="!hasError"></router-view>
          <div v-else>
            <h1>Error!</h1>
            <p>{{error}}</p>
          </div>
        </transition>
      </div>
    </section>
  </div>
</template>

<script>

  import { mapMutations, mapState } from "vuex";
  import api from "../lib/Api";
  import TWEEN from '@tweenjs/tween.js';

  export default {
    data() {
      return {
        api: api
      };
    },
    computed: {
      ...mapState({
        hasError: state => state.error !== null,
        error: state => state.error,
        authChecked: state => state.authChecked,
        initialLoad: state => state.initialLoad
      })
    },

    watch: {
      isLoading(val) {
        if (val) {
          this.$Progress.start();
        } else {
          this.$Progress.finish();
        }
      },

      initialLoad(val) {
        if (val) {
          this.$nextTick(() => {
            document.body.classList.remove("loading");
          });
        }
      }
    },

    created() {
      // Setup global animation loop
      function animate () {
        TWEEN.update();
        requestAnimationFrame(animate);
      }
      animate();

      if (this.user === null && this.authChecked === false) {
        this.checkAuthentication();
      }
    },

    mounted() {
      if (this.initialLoad) {
        this.$nextTick(() => {
          document.body.classList.remove("loading");
        });
      }
    },

    components: {
    }
  }

</script>