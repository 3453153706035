<template>
  <div>

    <h1 class="title">Create New User</h1>

    <app-validation-errors :errors="validationErrors"></app-validation-errors>

    <user-edit :user-obj="userObj" action="Creating"></user-edit>

    <button type="button" class="button is-primary" @click="save">Save</button>
    <router-link class="button is-secondary" to="/">Cancel</router-link>

  </div>
</template>

<script>

  import UserEdit from "./UserEdit";
  import api from "../lib/Api";
  import * as Errors from '../lib/Errors';

  export default {
    data() {
      return {
        validationErrors: {},
        userObj: {
          username: '',
          full_name: '',
          email: '',
          password: '',
          password_confirmation: ''
        }
      }
    },

    methods: {
      save() {
        this.validationErrors = {};
        this.loadResource(
          api.postUser(this.userObj)
            .then(() => this.checkAuthentication())
            .then(() => this.$router.push('/'))
            .catch(Errors.onlyFor(Errors.ApiValidationError, err => this.validationErrors = err.validationErrors()))
        );
      }
    },

    components: {
      UserEdit
    }
  }

</script>

<style lang="scss" scoped>

</style>