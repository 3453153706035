
function zeroPad(val, length) {
  return val.toString().padStart(length, "0");
}

// Ensure the given date is a Date object.
function toDate(date) {
  if (date instanceof Date) {
    return date;
  } else if (date === null || date.length === 0) {
    return null;
  } else {
    return new Date(date);
  }
}

function formatDate(dateObj) {
  if (dateObj) {
    return [dateObj.getMonth() + 1, dateObj.getDate(), dateObj.getFullYear() % 100].join("/");
  } else {
    return "";
  }
}

function formatDateForEdit(dateObj) {
  if (dateObj) {
    return [dateObj.getFullYear(), zeroPad(dateObj.getMonth() + 1, 2), zeroPad(dateObj.getDate(), 2)].join("-");
  } else {
    return "";
  }
}

function formatTimestamp(dateObj) {
  if (dateObj) {
    return formatDateForEdit(dateObj) + " " + formatTime(dateObj, false);
  } else {
    return "";
  }
}

function formatTime(dateObj, use12hour) {
  if (dateObj) {
    let h = dateObj.getHours();
    const m = zeroPad(dateObj.getMinutes(), 2);
    let meridiem = "";

    if (use12hour) {
      meridiem = " am";

      if (h === 0) {
        h = 12;
      } else if (h > 12) {
        h = h - 12;
        meridiem = " pm";
      }
    } else {
      h = h.toString().padStart(2, "0");
    }

    return h + ":" + m + meridiem;

  } else {
    return "";
  }
}

export default {
  toDate,
  formatDate,
  formatDateForEdit,
  formatTimestamp,
  formatTime
}