
function trackInstall(worker, cb) {
  worker.addEventListener('statechange', function() {
    if (worker.state == 'installed') {
      cb();
    }
  });
}

function trackActive(worker, cb) {
  worker.addEventListener('statechange', function() {
    if (worker.state == 'activated') {
      cb();
    }
  });
}

export function swUpdate() {
  navigator.serviceWorker.getRegistration().then(reg => {
    if (reg && reg.waiting) {
      trackActive(reg.waiting, () => window.location.reload(true));
      reg.waiting.postMessage("skipWaiting");
    } else {
      window.location.reload(true);
    }
  });

}

export function swInit(store) {

  const updateReady = () => store.commit("setUpdateAvailable", true);
  const clearUpdateReady = () => store.commit("setUpdateAvailable", false);

  if ('serviceWorker' in navigator) {
    navigator.serviceWorker.register('/sw.js')
      .then(function (reg) {
        console.log('Registration succeeded. Scope is ' + reg.scope);

        if (reg.waiting) {
          console.log('waiting');
          updateReady();
          trackActive(reg.waiting, clearUpdateReady);
        }

        reg.addEventListener('updatefound', function () {
          if (reg.active) {
            trackInstall(reg.installing, updateReady);
          }
        });

      }).catch(function (error) {
        console.log('Registration failed with ' + error);
      });
  }


}