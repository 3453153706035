<template>
  <div class="field">
    <label v-if="label.length" class="label is-small-mobile">{{ label }}</label>
    <div :class="controlClasses">
      <textarea v-if="isTextarea" :class="inputClasses" :value="value" @input="input" :disabled="disabled"></textarea>
      <input v-else :type="type" :class="inputClasses" :value="value" @input="input" :disabled="disabled">
      <app-icon class="is-right" icon="warning" v-if="validationError !== null"></app-icon>
    </div>
    <p v-if="helpMessage !== null" :class="helpClasses">
      {{ helpMessage }}
    </p>
  </div>
</template>

<script>

  export default {
    props: {
      label: {
        required: false,
        type: String,
        default: ""
      },
      value: {
        required: false,
        type: [String, Number],
        default: ""
      },
      type: {
        required: false,
        type: String,
        default: "text"
      },
      disabled: {
        type: Boolean,
        default: false
      },
      validationError: {
        required: false,
        type: String,
        default: null
      }
    },

    computed: {
      isTextarea() {
        return this.type === "textarea";
      },

      controlClasses() {
        return [
          "control",
          {
            "has-icons-right": this.validationError !== null
          }
        ]
      },

      inputClasses() {
        return [
          "is-small-mobile",
          {
            "textarea": this.isTextarea,
            "input": !this.isTextarea,
            "is-danger": this.validationError !== null
          }
        ]
      },

      helpMessage() {
        return this.validationError;
      },

      helpClasses() {
        return [
          "help",
          {
            "is-danger": this.validationError !== null
          }
        ];
      }
    },

    methods: {
      input(evt) {
        this.$emit("input", evt.target.value);
      }
    }
  }

</script>

<style lang="scss" scoped>
</style>