
import Vue from 'vue';
import { mapActions, mapGetters, mapMutations, mapState } from 'vuex';
import api from "../lib/Api";

Vue.mixin({
  data() {
    return {
      localLoadingCount: 0
    };
  },
  computed: {
    ...mapGetters([
      "isLoading",
      "isLoggedIn",
      "isAdmin"
    ]),
    ...mapState([
      "user"
    ]),
    localLoading() {
      return this.localLoadingCount > 0;
    }
  },
  methods: {
    ...mapActions([
      'updateCurrentUser'
    ]),
    ...mapMutations([
      'setError',
      'setLoading'
    ]),

    loadResource(promise) {
      this.setLoading(true);
      this.localLoadingCount = this.localLoadingCount + 1;

      return promise
        .catch(err => this.setError(err))
        .then(res => {
          this.setLoading(false);
          this.localLoadingCount = this.localLoadingCount - 1;
          return res;
        });
    },

    checkAuthentication() {
      return this.loadResource(this.updateCurrentUser());
    }
  }
});

function clickStrikeClick(evt) {
  const isStrikable = el => el && el.tagName === "LI";
  const strikeClass = "is-strikethrough";

  let t = evt.target;

  while (t !== null && t !== this && !isStrikable(t)) {
    t = t.parentElement;
  }

  if (isStrikable(t)) {
    const classList = t.className.split(" ");
    const strIdx = classList.findIndex(c => c === strikeClass);
    if (strIdx >= 0) {
      classList.splice(strIdx, 1);
    } else {
      classList.push(strikeClass);
    }

    t.className = classList.join(" ");
  }
}

Vue.directive('click-strike', {
  bind(el) {
    el.addEventListener("click", clickStrikeClick);
  },

  unbind(el) {
    el.removeEventListener("click", clickStrikeClick);
  }
});